<template>
  <b-row class="justify-content-center">
    <b-col md="8" xl="8">
      <b-card class="mb-5">
        <b-overlay :show="loading" rounded="sm">
          <h4 class="my-2">Detail Merchant</h4>
          <b-row>
            <b-col md="6" xl="6">
              <b-form-group
                label="NMID (jika telah memiliki QR selain QR Bank Danamon)"
                label-for="nmid"
              >
                <b-form-input id="nmid" v-model="form.nmid" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="Nama QR (max. 25 char)" label-for="name">
                <b-form-input
                  id="name"
                  maxlength="25"
                  v-model="form.name"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="my-2" />
          <b-row>
            <b-col xl="12">
              <b-form-group label="Alamat Penggunaan QR" label-for="address">
                <b-form-textarea id="address" v-model="form.address" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4">
              <b-form-group label="Kode Pos" label-for="postal-code">
                <b-form-input
                  id="postal-code"
                  v-model="form.postal_code"
                  type="number"
                  maxlength="5"
                  v-on:input="onPostalCodeChange"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4">
              <b-form-group label="Desa / Kelurahan" label-for="village">
                <b-form-input id="village" v-model="form.village" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4">
              <b-form-group label="Kecamatan" label-for="district">
                <b-form-input id="email" v-model="form.district" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4">
              <b-form-group label="Kota / Kabupaten" label-for="city">
                <b-form-input id="city" v-model="form.city" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4">
              <b-form-group label="Provinsi" label-for="province">
                <b-form-input id="province" v-model="form.province" />
                <b-form-invalid-feedback>
                  Tidak boleh kosong
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="my-2" />
          <b-row>
            <b-col md="6" xl="4">
              <b-form-group label="Jenis usaha" label-for="business-type">
                <b-form-select
                  id="business-type"
                  v-model="form.business_type"
                  :options="business_types"
                  @change="onBusinessTypeChange"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4">
              <b-form-group label="Tipe Bidang Usaha" label-for="category-type">
                <b-form-select
                  id="category-type"
                  v-model="form.merchant_type"
                  :options="category_types"
                  @change="onCategoryTypeChange"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4">
              <b-form-group label="Bidang Usaha" label-for="category">
                <b-form-select
                  id="category"
                  v-model="form.merchant_category"
                  :options="categories"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4" v-if="form.merchant_category == 6">
              <b-form-group label="Bidang Usaha Lainnya" label-for="omzet">
                <b-form-input
                  id="category-type"
                  v-model="form.merchant_category_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4">
              <b-form-group label="Rata-rata Omset per Bulan" label-for="omzet">
                <b-form-select
                  id="category-type"
                  v-model="form.omzet"
                  :options="omzets"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="form.business_type == 2">
            <b-col cols="12">
              <hr class="my-2" />
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="Nama Badan Hukum" label-for="business_name">
                <b-form-input id="business_name" v-model="form.business_name" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="6">
              <b-form-group label="NPWP" label-for="npwp">
                <b-form-input
                  id="npwp"
                  max="25"
                  v-model="form.npwp"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-button block class="mt-2" variant="success" @click="onSend()"
                >Selanjutnya</b-button
              >
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BButton,
  BOverlay,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BButton,
    BOverlay,
    BFormInvalidFeedback,
  },
  data() {
    return {
      loading: false,
      business_type: 0,
      business_types: [],
      category_type: 0,
      category_types: [],
      categories: [],
      category: null,
      omzet: 0,
      omzets: [],
      postal_codes: [],
      form: {
        nmid: null,
        name: "",
        address: null,
        village: null,
        district: null,
        city: null,
        province: null,
        postal_code: "",
        merchant_type: null,
        merchant_category: null,
        merchant_category_name: null,
        business_type: null,
        business_name: null,
        npwp: null,
        omzet: null,
      },
    };
  },
  created() {
    this.getBusinessType();
    this.getCategoryType();
    this.getOmzet();
  },
  methods: {
    getBusinessType() {
      this.$http
        .get("/merchant/business-types")
        .then((response) => {
          let $data = response.data.data;

          for (let i = 0; i < $data.length; i++) {
            this.business_types.push({
              value: $data[i].id,
              text: $data[i].name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategoryType() {
      this.$http
        .get("/merchant/types")
        .then((response) => {
          let $data = response.data.data;

          for (let i = 0; i < $data.length; i++) {
            this.category_types.push({
              value: $data[i].id,
              text: $data[i].name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategories($id) {
      this.categories = [];
      this.$http
        .get("/merchant/categories?type=" + $id)
        .then((response) => {
          let $data = response.data.data;

          for (let i = 0; i < $data.length; i++) {
            this.categories.push({
              value: $data[i].id,
              text: $data[i].name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOmzet() {
      this.$http
        .get("/merchant/omzets")
        .then((response) => {
          let $data = response.data.data;

          for (let i = 0; i < $data.length; i++) {
            this.omzets.push({
              value: $data[i].id,
              text: $data[i].name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPostalCode($code) {
      this.$http
        .get("/merchant/postal-codes?code=" + $code)
        .then((response) => {
          let $data = response.data.data;
          if ($data !== null) {
            this.form.village = $data.village;
            this.form.district = $data.district;
            this.form.city = $data.city;
            this.form.province = $data.province;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSend() {
      if (this.validation()) {
        this.loading = true;
        this.$http
          .post("/merchants/add", this.form)
          .then((res) => {
            this.loading = false;
            this.$bvToast.toast("Berhasil", {
              title: "Berhasil",
              variant: "success",
              solid: true,
              autoHideDelay: 1000,
            });
            this.$store.state.app.merchantId = res.data.data.id;
            this.$store.state.app.businessType = this.form.business_type;
            localStorage.setItem("merchantId", res.data.data.id);
            localStorage.setItem("businessType", this.form.business_type);
            this.$router.push("/merchant/add/profile");
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 1000,
            });
          });
      } else {
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
    onBusinessTypeChange(i) {
      this.business_type = i;
    },
    onCategoryTypeChange(i) {
      this.getCategories(i);
    },
    onPostalCodeChange(i) {
      if (i.length == 5) {
        this.getPostalCode(i);
      } else {
        this.form.village = null;
        this.form.district = null;
        this.form.city = null;
        this.form.province = null;
      }
    },
    validation() {
      if (this.form.name.length === 0) {
        this.$bvToast.toast("Nama Merchant QR tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.name.length < 5) {
        this.$bvToast.toast("Nama Merchant QR min. 5 karakter", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.address === null) {
        this.$bvToast.toast("Alamat tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.postal_code.length === 0) {
        this.$bvToast.toast("Kode pos tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.postal_code.length !== 5) {
        this.$bvToast.toast("Kode pos harus 5 Angka", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.village === null) {
        this.$bvToast.toast("Desa/Kelurahan tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.district === null) {
        this.$bvToast.toast("Kecamatan tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.city === null) {
        this.$bvToast.toast("Kota/Kabupaten tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.province === null) {
        this.$bvToast.toast("Provinsi tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.business_type === null) {
        this.$bvToast.toast("Jenis usaha tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.merchant_type === null) {
        this.$bvToast.toast("Tipe bidang usaha tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.merchant_category === null) {
        this.$bvToast.toast("Bidang usaha tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (
        this.form.merchant_category === 6 &&
        this.form.merchant_category_name === null
      ) {
        this.$bvToast.toast("Bidang usaha lainnya tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.omzet === null) {
        this.$bvToast.toast("Rata-rata omset per bulan tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.business_type === 2 && this.form.business_name === null) {
        this.$bvToast.toast("Nama badan usaha tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.business_type === 2 && this.form.npwp === null) {
        this.$bvToast.toast("NPWP tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
